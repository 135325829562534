<template>
  <div class="card p-shadow-6">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />

    <ClassificationForm
      :showToast="true"
      :show="edit.show"
      :sellerId="edit.sellerId"
      :businessId="edit.businessId"
      :businessName="edit.businessName"
      :classification="edit.classification"
      :selected="edit.selected"
      v-on:cancel="cancelEditBusiness"
      v-on:savedBusinessClassification="editBusinessOk"
    />
    <h1>Classificação dos Negócios</h1>
    <div class="p-mb-4">
      <W4MstreakIndicators
        :indicators="streakIndicators"
        :loader="indicatorLoading"
        v-on:W4MstreakIndicatorsClick="streakIndicatorsClick"
      />
    </div>
    <div style="height: 65vh">
      <DataTable
        :value="businessList"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="indicatorLoading"
        class="p-datatable-sm"
        :sortOrder="1"
        sortField="classification.nameAlias"
        responsiveLayout="scroll"
        removableSort
        sortMode="single"
        :scrollable="true"
        scrollHeight="flex"
        :selection.sync="selectedBusiness"
      >
        <template #empty> Não existem Negócios. </template>
        <template #loading> A carregar. Por favor Aguarde... </template>
        <Column
          selectionMode="multiple"
          :headerStyle="{ 'min-width': '3%', 'max-width': '3%' }"
          :filterHeaderStyle="{ 'min-width': '3%', 'max-width': '3%' }"
          :bodyStyle="{ 'min-width': '3%', 'max-width': '3%' }"
        ></Column>
        <Column
          sortable
          field="classification.nameAlias"
          header="Nome"
          filterField="classification.nameAlias"
          :headerStyle="{ 'min-width': '30%', 'max-width': '30%' }"
          :filterHeaderStyle="{ 'min-width': '30%', 'max-width': '30%' }"
          :bodyStyle="{ 'min-width': '30%', 'max-width': '30%' }"
        >
          <template #body="slotProps">
            <span
              class="clickable-item"
              @click="editBusiness(slotProps.data)"
              >{{
                slotProps.data.seller_name.trim() != ""
                  ? slotProps.data.classification.nameAlias
                  : "_______"
              }}</span
            >
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <!-- <Column
          sortable
          field="classification"
          header="Nome Marketing"
          filterField="classification.marketing_name"
          :headerStyle="{ 'min-width': '25%', 'max-width': '25%' }"
          :filterHeaderStyle="{ 'min-width': '25%', 'max-width': '25%' }"
          :bodyStyle="{ 'min-width': '25%', 'max-width': '25%' }"
        >
          <template #body="slotProps">
            {{ slotProps.data.classification.marketing_name }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column> -->
        <Column
          sortable
          field="date"
          header="Data Venda"
          filterField="date"
          dataType="date"
          :headerStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :filterHeaderStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :bodyStyle="{
            'min-width': '10%',
            'max-width': '10%',
            'text-align': 'center',
            display: 'table-cell',
          }"
          :headerClass="'align-center'"
        >
          <template #body="slotProps">
            <span>{{ getDate(slotProps.data.date) }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <v-date-picker
              v-model="filterModel.value"
              @input="filterCallback()"
              :masks="{
                input: 'YYYY-MM-DD',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="p-column-filter p-inputtext p-component"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </template>
        </Column>
        <Column
          sortable
          field="seller_name"
          header="Vendedor"
          filterField="seller_name"
          :headerStyle="{ 'min-width': '12%', 'max-width': '12%' }"
          :filterHeaderStyle="{ 'min-width': '12%', 'max-width': '12%' }"
          :bodyStyle="{
            'min-width': '12%',
            'max-width': '12%',
            'text-align': 'center',
            display: 'table-cell',
          }"
          :headerClass="'align-center'"
        >
          <template #body="slotProps">
            {{ slotProps.data.seller_name }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <!-- <Column
          v-if="can('setSellerEditBusinessClassification')"
          sortable
          field="classification.seller_edit"
          header="Vendedor Edita"
          filterField="classification.seller_edit"
          :headerStyle="{ 'min-width': '8%', 'max-width': '8%' }"
          :filterHeaderStyle="{ 'min-width': '8%', 'max-width': '8%' }"
          :bodyStyle="{ 'min-width': '8%', 'max-width': '8%' }"
        >
          <template #body="slotProps">
            <span
              class="clickable-item"
              @click="editBusiness(slotProps.data)"
              >{{
                slotProps.data.classification.seller_edit == 1 ? "Sim" : "Não"
              }}</span
            >
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="[
                { values: 0, label: 'Não' },
                { values: 1, label: 'Sim' },
              ]"
              optionValue="value"
              optionLabel="label"
              class="p-column-filter"
              :showClear="true"
              :style="{
                width: '80%',
              }"
            >
            </Dropdown>
          </template>
        </Column> -->
        <!-- <Column
          sortable
          field="classification.portfolio"
          header="Portfólio"
          filterField="classification.portfolio"
          :headerStyle="{ 'min-width': '8%', 'max-width': '8%' }"
          :filterHeaderStyle="{ 'min-width': '8%', 'max-width': '8%' }"
          :bodyStyle="{ 'min-width': '8%', 'max-width': '8%' }"
        >
          <template #body="slotProps">
            <span
              class="clickable-item"
              @click="editBusiness(slotProps.data)"
              >{{
                slotProps.data.classification.portfolio == 1 ? "Sim" : "Não"
              }}</span
            >
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="[
                { values: 0, label: 'Não' },
                { values: 1, label: 'Sim' },
              ]"
              optionValue="value"
              optionLabel="label"
              class="p-column-filter"
              :showClear="true"
              :style="{
                width: '80%',
              }"
            >
            </Dropdown>
          </template>
        </Column>
        -->
        <Column
          sortable
          field="classification.case_study"
          header="Caso de Estudo"
          filterField="classification.case_study"
          :headerStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :filterHeaderStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :bodyStyle="{
            'min-width': '10%',
            'max-width': '10%',
            'text-align': 'center',
            display: 'table-cell',
          }"
          :headerClass="'align-center'"
        >
          <template #body="slotProps">
            <span>{{
              slotProps.data.classification.case_study == 1 ? "Sim" : "Não"
            }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="[
                { value: 0, label: 'Não' },
                { value: 1, label: 'Sim' },
              ]"
              optionValue="value"
              optionLabel="label"
              class="p-column-filter"
              :showClear="true"
              :style="{
                width: '80%',
              }"
            >
            </Dropdown>
          </template>
        </Column>
        <Column
          sortable
          field="classification.closed"
          header="Edição Finalizada"
          filterField="classification.closed"
          :headerStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :filterHeaderStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :bodyStyle="{
            'min-width': '10%',
            'max-width': '10%',
            'text-align': 'center',
            display: 'table-cell',
          }"
          :headerClass="'align-center'"
        >
          <template #body="slotProps">
            <span>{{
              slotProps.data.classification.closed == 1 ? "Sim" : "Não"
            }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="[
                { value: 0, label: 'Não' },
                { value: 1, label: 'Sim' },
              ]"
              optionValue="value"
              optionLabel="label"
              class="p-column-filter"
              :showClear="true"
              :style="{
                width: '80%',
              }"
            >
            </Dropdown>
          </template>
        </Column>
        <!-- <Column
          sortable
          field="classification.social_networks"
          header="Redes Sociais"
          filterField="classification.social_networks"
          :headerStyle="{ 'min-width': '8%', 'max-width': '8%' }"
          :filterHeaderStyle="{ 'min-width': '8%', 'max-width': '8%' }"
          :bodyStyle="{ 'min-width': '8%', 'max-width': '8%' }"
        >
          <template #body="slotProps">
            <span
              class="clickable-item"
              @click="editBusiness(slotProps.data)"
              >{{
                slotProps.data.classification.social_networks == 1
                  ? "Sim"
                  : "Não"
              }}</span
            >
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="[
                { values: 0, label: 'Não' },
                { values: 1, label: 'Sim' },
              ]"
              optionValue="value"
              optionLabel="label"
              class="p-column-filter"
              :showClear="true"
              :style="{
                width: '80%',
              }"
            >
            </Dropdown>
          </template>
        </Column>
        <Column
          sortable
          field="classification.sites"
          header="Sites"
          filterField="classification.sites"
          :headerStyle="{ 'min-width': '8%', 'max-width': '8%' }"
          :filterHeaderStyle="{ 'min-width': '8%', 'max-width': '8%' }"
          :bodyStyle="{ 'min-width': '8%', 'max-width': '8%' }"
        >
          <template #body="slotProps">
            <span
              class="clickable-item"
              @click="editBusiness(slotProps.data)"
              >{{
                slotProps.data.classification.sites == 1 ? "Sim" : "Não"
              }}</span
            >
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="[
                { values: 0, label: 'Não' },
                { values: 1, label: 'Sim' },
              ]"
              optionValue="value"
              optionLabel="label"
              class="p-column-filter"
              :showClear="true"
              :style="{
                width: '80%',
              }"
            >
            </Dropdown>
          </template>
        </Column> -->
        <Column
          sortable
          field="classification.lastUpdate"
          header="Atualizado"
          filterField="classification.lastUpdate"
          dataType="date"
          :headerStyle="{ 'min-width': '20%', 'max-width': '20%' }"
          :filterHeaderStyle="{ 'min-width': '20%', 'max-width': '20%' }"
          :bodyStyle="{
            'min-width': '20%',
            'max-width': '20%',
            'text-align': 'center',
            display: 'table-cell',
          }"
          :headerClass="'align-center'"
        >
          <template #body="slotProps">
            <span v-if="slotProps.data.classification.lastUpdate != ''">{{ getDateTime(slotProps.data.classification.lastUpdate) }}
              | {{ slotProps.data.classification.lastUpdateBy }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <v-date-picker
              v-model="filterModel.value"
              @input="filterCallback()"
              :masks="{
                input: 'YYYY-MM-DD',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="p-column-filter p-inputtext p-component"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </template>
        </Column>
        <Column
          :headerStyle="{ 'min-width': '5%', 'max-width': '5%' }"
          :filterHeaderStyle="{ 'min-width': '5%', 'max-width': '5%' }"
          :bodyStyle="{ 'min-width': '5%', 'max-width': '5%' }"
        >
          <template #body="slotProps">
            <Button
              v-if="slotProps.data.classification.id != undefined"
              icon="pi pi-eye"
              class="p-button-rounded p-button-success p-button-text"
              @click="goToClassification(slotProps.data.classification.id)"
            />
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api/";
import { getOnlyDate, getOnlyTime } from "../helpers/helpers";
import businessService from "../services/business.service";
import ClassificationForm from "../components/business/ClassificationForm.vue";
import W4MstreakIndicators from "../components/W4Mstreak/W4MstreakIndicators.vue";
export default {
  name: "BusinessClassification",
  components: {
    ClassificationForm,
    W4MstreakIndicators,
  },
  data() {
    return {
      loading: false,
      indicatorLoading: true,
      businessList: [],
      streakIndicators: [],
      solutionsList: [],
      businessAreasList: [],
      selectedBusiness: null,
      currentIndicator: null,
      filters: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "classification.nameAlias": {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        resume: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "classification.marketing_name": {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        seller_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        sale_date: { value: null, matchMode: FilterMatchMode.DATE_IS },
        date: { value: null, matchMode: FilterMatchMode.DATE_IS },
        "classification.seller_edit": {
          value: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        "classification.closed": {
          value: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        "classification.portfolio": {
          value: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        "classification.case_study": {
          value: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        "classification.social_networks": {
          value: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        "classification.sites": {
          value: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        "classification.lastUpdate": {
          value: null,
          matchMode: FilterMatchMode.DATE_IS,
        },
      },
      edit: {
        show: false,
        sellerId: null,
        businessId: null,
        businessName: null,
        classification: {},
        business: null,
        selected: null,
      },
    };
  },
  async created() {
    this.loading = true;
    this.solutionsList = await businessService.getBusinessSolutionsList();
    this.businessAreasList = await businessService.getBusinessAreasList();
    this.initStreakIndicators();
    return (this.loading = false);
  },
  methods: {
    async initStreakIndicators() {
      this.indicatorLoading = true;
      this.streakIndicators = [];
      if (this.can("hideBusinessClassification")) {
        this.streakIndicators.push({
          title: "Por Classificar",
          counter: await businessService.countBusinesToClassify(),
          color: "#ff9900",
        });
      }
      this.streakIndicators.push({
        title: "Por Finalizar Edição",
        counter: await businessService.countBusinesToClose(),
        color: "#f4d909",
      });
      this.streakIndicators.push({
        title: "Portfólio",
        counter: await businessService.countBusinesDisclose(),
        color: "#00cc6c",
      });
      this.streakIndicators.push({
        title: "Caso de Estudo",
        counter: await businessService.countBusinesCaseStudy(),
        color: "#3f89ff",
      });
      this.streakIndicators.push({
        title: "Não Divulgar",
        counter: await businessService.countBusinesDoNotDisclose(),
        color: "#fc2d2d",
      });
      // if (this.can("hideBusinessClassification")) {
      //   this.streakIndicators.push({
      //     title: "Ocultos",
      //     counter: await businessService.countBusinesHide(),
      //     color: "#a0a0a0",
      //   });
      // }
      this.indicatorLoading = false;
    },
    getDate(date) {
      return `${getOnlyDate(date)}`;
    },
    getDateTime(date) {

      return `${getOnlyDate(date)} ${getOnlyTime(date)}`;
    },
    editBusiness(business) {
      let classification = { ...business.classification };
      if (classification.case_study == 1) {
        classification.portfolio = true;
      } else {
        classification.portfolio = classification.portfolio == 1 ? true : false;
      }
      classification.case_study = classification.case_study == 1 ? true : false;
      classification.closed = classification.closed == 1 ? true : false;
      classification.social_networks =
        classification.social_networks == 1 ? true : false;
      classification.seller_edit =
        classification.business_id == undefined
          ? true
          : classification.seller_edit == 1
          ? true
          : false;
      classification.hide = classification.hide == 1 ? true : false;
      classification.sites = classification.sites == 1 ? true : false;

      if (classification.business_areas != null) {
        classification.business_areas = classification.business_areas
          .split(";")
          .map(Number);
      }
      if (classification.solutions != null) {
        classification.solutions = classification.solutions
          .split(";")
          .map(Number);
      }
      if (classification.id != undefined && classification.hide == 1) {
        classification.currentStatus = 3;
      } else if (
        classification.id != undefined &&
        classification.hide == 0 &&
        classification.seller_edit == 0
      ) {
        classification.currentStatus = 1;
      } else if (
        classification.id != undefined &&
        classification.hide == 0 &&
        classification.seller_edit == 1
      ) {
        classification.currentStatus = 2;
      } else {
        classification.currentStatus = null;
      }
      return (this.edit = {
        show: true,
        sellerId: parseInt(business.seller_id),
        businessId: business.reference,
        businessName: business.name,
        classification: classification,
        business: business,
        selected:
          this.selectedBusiness && this.selectedBusiness.length > 1
            ? this.selectedBusiness
            : null,
      });
    },
    cancelEditBusiness() {
      this.selectedBusiness = null;
      return (this.edit = {
        show: false,
        sellerId: null,
        businessId: null,
        businessName: null,
        classification: {},
        business: null,
        selected: null,
      });
    },
    async editBusinessOk(response) {
      if (response.status == "ok") {
        this.loading = true;
        this.initStreakIndicators();
        if (this.currentIndicator == "Por Classificar") {
          this.businessList = this.formatClassification(
            await businessService.getBusinesToClassify()
          );
        } else if (this.currentIndicator == "Portfólio") {
          this.businessList = this.formatClassification(
            await businessService.getBusinesDisclose()
          );
        } else if (this.currentIndicator == "Não Divulgar") {
          this.businessList = this.formatClassification(
            await businessService.getBusinesDoNotDisclose()
          );
        } else if (this.currentIndicator == "Ocultos") {
          this.businessList = this.formatClassification(
            await businessService.getBusinesHide()
          );
        } else if (this.currentIndicator == "Caso de Estudo") {
          this.businessList = this.formatClassification(
            await businessService.getBusinesCaseStudy()
          );
        } else if (this.currentIndicator == "Por Finalizar Edição") {
          this.businessList = this.formatClassification(
            await businessService.getBusinesToClose()
          );
        }
        this.loading = false;
      }
      return this.cancelEditBusiness();
    },
    async streakIndicatorsClick(indicator) {
      this.loading = true;
      this.currentIndicator = indicator.indicatorName;
      this.businessList = [];
      if (this.currentIndicator == "Por Classificar") {
        this.businessList = this.formatClassification(
          await businessService.getBusinesToClassify()
        );
      } else if (this.currentIndicator == "Portfólio") {
        this.businessList = this.formatClassification(
          await businessService.getBusinesDisclose()
        );
      } else if (this.currentIndicator == "Não Divulgar") {
        this.businessList = this.formatClassification(
          await businessService.getBusinesDoNotDisclose()
        );
      } else if (this.currentIndicator == "Ocultos") {
        this.businessList = this.formatClassification(
          await businessService.getBusinesHide()
        );
      } else if (this.currentIndicator == "Caso de Estudo") {
        this.businessList = this.formatClassification(
          await businessService.getBusinesCaseStudy()
        );
      } else if (this.currentIndicator == "Por Finalizar Edição") {
        this.businessList = this.formatClassification(
          await businessService.getBusinesToClose()
        );
      }else {
        this.businessList = this.allBusiness;
      }
      return (this.loading = false);
    },
    formatClassification(classificaton) {
      let response = [];
      classificaton.map((solution) => {
        solution.date = new Date(`${solution.date}`);
        solution.sale_date = new Date(`${solution.sale_date}`);
        if (solution.classification == null) {
          solution.classification = {
            closed: 0,
            seller_edit: 0,
            portfolio: 0,
            case_study: 0,
            social_networks: 0,
            sites: 0,
            nameAlias: solution.name,
            lastUpdate: '',
            lastUpdateBy: '',
          };
        } else {
          solution.classification.seller_edit =
            solution.classification.seller_edit == 1 ? 1 : 0;
          solution.classification.closed =
            solution.classification.closed == 1 ? 1 : 0;
          solution.classification.portfolio =
            solution.classification.portfolio == 1 ? 1 : 0;
          solution.classification.case_study =
            solution.classification.case_study == 1 ? 1 : 0;
          solution.classification.social_networks =
            solution.classification.social_networks == 1 ? 1 : 0;
          solution.classification.sites =
            solution.classification.sites == 1 ? 1 : 0;
          solution.classification.nameAlias =
            solution.classification.marketing_name &&
            solution.classification.marketing_name != "" &&
            solution.classification.marketing_name != "undefined"
              ? solution.classification.marketing_name
              : solution.name;
          solution.classification.lastUpdate = new Date(
            solution.classification.updated_at
          );
          solution.classification.lastUpdateBy = solution.classification.updated_user.username;
        }
        response.push(solution);
      });
      return response;
    },
    goToClassification(id) {
      let route = this.$router.resolve({
        path: `/business/classification/${id}`,
      });
      window.open(route.href, "_blank");
    },
  },
};
</script>
<style>
.align-center .p-column-header-content {
  justify-content: center !important;
}
th.align-center {
  justify-content: center !important;
}
</style>
